footer.footer{
    border-top: 2px solid #F35E0A;
background: #000;
}

footer.footer .content{
    height: 170px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

footer.footer .content .copyright {
    color: #e05708;
    font-family: League Spartan;
    font-size: 22.861px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

footer.footer .content .navitems{
    display: flex;
    gap: 2rem;
    align-items: center;
    
    color: #FFF;
    font-size: 22.861px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    
}

    footer.footer .content .navitems a{
        color: #FFF;
        text-decoration: none;
        cursor: pointer;
       transition: all 0.5s ease;
    }
 
    footer.footer .content .navitems a:hover,
    footer.footer .content .navitems a:focus{
        color: #e05708;
    }
   
    footer.footer .content .icons{
        display: flex;
        gap: 1rem;
        align-items: center;
    }
   

    footer.footer .content .icons img{
        height: 2rem;
        max-width: 2.3rem;
        object-fit: contain;
        width: auto;
         display: block;
    }
   
    footer.footer .content .icons a{
        transition: all 0.6s cubic-bezier(0.16, 1, 0.3, 1);
        }
   
    footer.footer .content .icons a:hover{
       transform: scale(1.2);
    }


@media (max-width: 750px) {
    footer.footer .content{
        height: fit-content;
        padding: 50px 0;
        display: flex;
        flex-direction: column-reverse;
        gap: 1rem;
        justify-content: space-between;
        align-items: center;
    }

    footer.footer .content .navitems{
        flex-wrap: wrap;
        gap: 1rem;
        font-size: 18px;
        justify-content: center;
        padding: 1rem 0;
        
    }
    
    footer.footer .content .navitems a{
        flex-basis: 40%;
        white-space: nowrap;
        text-align: center;
    }
    footer.footer .content .navitems span.nomob{
        display: none;
    }
}