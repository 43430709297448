.marqueeSec {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  padding: 24px 0;
}

.marqueeSec .marqueeCon {
  height: 100%;
  position: relative;
}
.marqueeSec .marqueeCon .marqueeDiv {
  height: 100%;
  display: flex;
  width: 100%;
  flex-wrap: nowrap;
  align-items: center;
}
.marqueeSec .marqueeCon .marqueeDiv2 {
  height: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  position: absolute;
  left: 100%;
  top: 0%;
}

.marqueeSec .marqueeCon .marqueeDiv > div,
.marqueeSec .marqueeCon .marqueeDiv2 > div {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  white-space: nowrap;
  margin: 0 1ch;
  color: #fff;
  font-size: 176.778px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  text-transform: uppercase;
}

.marqueeSec .marqueeCon .marqueeDiv > div > small,
.marqueeSec .marqueeCon .marqueeDiv2 > div  > small{
  font-size: 50.778px;
  font-weight: 500;
  line-height: 1;
}

.marqueeSec .marqueeCon .marqueeDiv > div:nth-child(even),
.marqueeSec .marqueeCon .marqueeDiv2 > div:nth-child(even) {
  color: #F35E0A;
}

@media (max-width: 750px) {
  .marqueeSec {
    padding: 24px 0;
  }

  .marqueeSec .marqueeCon .marqueeDiv > div,
  .marqueeSec .marqueeCon .marqueeDiv2 > div {
    font-size: 50px;
    margin: 0 0.5ch;
  }

  .marqueeSec .marqueeCon .marqueeDiv > div > small,
.marqueeSec .marqueeCon .marqueeDiv2 > div  > small{
  font-size: 20px;
}
}
