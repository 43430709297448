.BtnPrimary {
  border-radius: 15px;
  border: 1px solid #373737;
  background: #F35E0A;
  display: inline-flex;
  align-items: center;
  padding: 14px 25px 14px 17px;
  align-items: flex-end;
  gap: 8.395px;
  color: #fff;
  font-size: 22.765px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  cursor: pointer;
  transition: all 0.5s ease;
}

.BtnPrimary:hover {
    background: #e05708;
 
}

.BtnPrimary .icon {
  width: 24px;
  height: 24px;
}

.BtnPrimary .icon img{
  width: 100%;
  height: 100%;
  display: block;
}


@media (max-width:750px) {
    .BtnPrimary {
        border-radius: 10px;
        padding: 8px 12px;
        font-size: 16px;
        gap: 5px;
      }

      .BtnPrimary .icon {
        width: 20px;
        height: 20px;
      }
      
}